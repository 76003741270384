import React from "react"

import Markdown from "src/components/Markdown"
import StandardContent from "src/components/StandardContent"
import ContentPageStyles from "src/styles/ContentPageStyles"
import privacyMd from "src/content/privacy-2020-11-02.md"
import SEO from "src/components/SEO"
import replaceInContent from "src/content/replaceInContent"

const replacements = {
  nyc: {
    CONTACT_EMAIL_VINTAGE_MAP: "liisa@nycvintagemap.com",
    CITY_NAME: "New York City",
    EFFECTIVE_DATE: "November 18, 2020",
    SITE_NAME: "NYC Vintage Map",
    SITE_DOMAIN: "nycvintagemap.com",
  },
  la: {
    CONTACT_EMAIL_VINTAGE_MAP: "liisa@gem.app",
    CITY_NAME: "Los Angeles",
    EFFECTIVE_DATE: "Dec 9, 2021",
    SITE_NAME: "LA Vintage Map",
    SITE_DOMAIN: "lavintagemap.com",
  },
}

export default function PrivacyContent() {
  return (
    <>
      <SEO title="Privacy Policy" />
      <StandardContent>
        <ContentPageStyles>
          <Markdown>
            {replaceInContent({
              content: privacyMd,
              replacements: replacements[process.env.GATSBY_CITY],
            })}
          </Markdown>
        </ContentPageStyles>
      </StandardContent>
    </>
  )
}
