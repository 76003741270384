export default function replaceInContent({
  content,
  replacements,
}: {
  content: string
  replacements: { [key: string]: string }
}): string {
  let result = content
  Object.entries(replacements).forEach(([key, value]) => {
    // Build RegExp to replace all (string.replaceAll not yet supported)
    result = result.replace(new RegExp(key, "g"), value)
  })
  return result
}
